// Mui
import { Box, Container, Grid, Typography } from "@mui/material";

// Router
import { Link } from "react-router-dom";
import mywotlogo from "assets/images/mywotlogo.png";

import config from "configs/api";
// Compoents
import Logo from "components/Logo/Logo";

function Footer({ style = {} }) {
  return (
    <Box sx={{ background: (t) => t.palette.gradients.default }}>
      <Container sx={{ py: 3, ...style }} maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={6} md={3}>
            <Logo />
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="body2" color="primary.first" fontWeight={800}>
              Сотрудничество
            </Typography>
            <Box sx={{ mt: 2 }}>
              <TextLink name="Правила обмена" link="/rules" />
              <TextLink name="Партнерам" link="/сooperation" />
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="body2" color="primary.second" fontWeight={800}>
              Компания
            </Typography>
            <Box sx={{ mt: 2 }}>
              {/* <TextLink name="О нас" link="/about-us" /> */}
              {/* <TextLink name="Отзывы" link="/reviews" /> */}
              <TextLink name="Контакты" link="/our-contacts" />
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="body2" color="primary.third" fontWeight={800}>
              Помощь
            </Typography>
            <Box sx={{ mt: 2 }}>
              {/* <TextLink name="Служба поддержки" link="/about-us" /> */}
              {/* <TextLink name="FAQ" link="/faq" /> */}
              <TextLink
                onClick={() =>
                  (window.location.href = `mailto:${config.EMAIL}`)
                }
                name={config.EMAIL}
                link="/our-contacts"
              />
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <a href="https://www.mywot.com/scorecard/hermex.cc" target="_blank">
              <img width="80px" alt="MyWot" src={mywotlogo} />
            </a>
          </Box>
          <Box
            sx={{
              ml: 2,
            }}
          >
            <a
              href="https://exchangesumo.com"
              title="Сервис поиска выгодного курса обмена"
            >
              <img
                src="https://b.exchangesumo.com/i/1.svg"
                width="88"
                height="31"
                border="0"
              />
            </a>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

function TextLink({ name, link, onClick }) {
  return (
    <Typography
      onClick={onClick}
      to={link}
      component={Link}
      sx={{
        mb: 2,
        display: "block",
        textDecoration: "none",
        wordBreak: "break-word",
      }}
      variant="body2"
      fontWeight={200}
    >
      {name}
    </Typography>
  );
}
export default Footer;
